define("ccaa-frontend/routes/subsidies/swpp/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SubsidiesSwppIndexRoute extends Ember.Route {
    async model() {
      let faqs = this.store.query('faq', {
        type: "swpp"
      });
      let changes = this.store.findAll('swpp-change');
      let news = this.store.query('news-article', {
        status: 'active'
      });
      news = news.filter(function (article) {
        let flag = false;
        article.tags.forEach(function (tag) {
          if (tag.name["en-ca"] == "SWPP" || tag.name["en-ca"] == "swpp") {
            flag = true;
          }
        });
        return flag;
      });
      return {
        faqs: faqs,
        changes: changes,
        news: news
      };
    }

  }

  _exports.default = SubsidiesSwppIndexRoute;
});