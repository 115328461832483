define("ccaa-frontend/helpers/truncate-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function truncateTitle([content, length]) {
    if (content.length > length) {
      let text = content.substring(0, length);
      return text + "...";
    } else {
      return content;
    }
  });

  _exports.default = _default;
});