define("ccaa-frontend/routes/contact/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ContactRoute extends Ember.Route {
    async model() {
      let staffMembers = await this.store.findAll('staff-member');
      return {
        staffMembers: staffMembers
      };
    }

  }

  _exports.default = ContactRoute;
});