define("ccaa-frontend/routes/swpp/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SwppRoute extends Ember.Route {}

  _exports.default = SwppRoute;
});