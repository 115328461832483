define("ccaa-frontend/adapters/application", ["exports", "ember-torusoft-api-adapter"], function (_exports, _emberTorusoftApiAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class applicationAdapter extends _emberTorusoftApiAdapter.TorusoftApiAdapter {// handleResponse(status, headers, payload){
    //   return super.super.handleResponse(...arguments);
    // }
  }

  _exports.default = applicationAdapter;
});