define("ccaa-frontend/routes/rfp/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RfpRoute extends Ember.Route {
    async model() {
      let rfps = await this.store.findAll('rfp');
      let faqs = await this.store.findAll('faq');
      faqs = faqs.filterBy('type', 'rfp');
      return {
        rfps: rfps,
        faqs: faqs
      };
    }

  }

  _exports.default = RfpRoute;
});