define("ccaa-frontend/routes/educators/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class EducatorsRoute extends Ember.Route {
    async model() {
      let links = await this.store.findAll('educator-link');
      return {
        links: links
      };
    }

  }

  _exports.default = EducatorsRoute;
});