define("ccaa-frontend/routes/join/controller", ["exports", "ccaa-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let JoinController = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class JoinController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _defineProperty(this, "queryParams", ['preserveScrollPosition', 'for', 'addCompany']);

      _defineProperty(this, "for", null);

      _defineProperty(this, "addCompany", null);

      _defineProperty(this, "provinces", {
        "AB": {
          "en-ca": "Alberta",
          "fr-ca": "L'Alberta"
        },
        "BC": {
          "en-ca": "British Columbia",
          "fr-ca": "La Colombie-Britannique"
        },
        "MB": {
          "en-ca": "Manitoba",
          "fr-ca": "Le Manitoba"
        },
        "NB": {
          "en-ca": "New Brunswick",
          "fr-ca": "Le Nouveau-Brunswick"
        },
        "NL": {
          "en-ca": "Newfoundland and Labrador",
          "fr-ca": "La Terre-Neuve-et-Labrador"
        },
        "NT": {
          "en-ca": "Northwest Territories",
          "fr-ca": "Les Territoires du Nord-Ouest"
        },
        "NS": {
          "en-ca": "Nova Scotia",
          "fr-ca": "La Nouvelle-Écosse"
        },
        "NU": {
          "en-ca": "Nunavut",
          "fr-ca": "Le Nunavut"
        },
        "ON": {
          "en-ca": "Ontario",
          "fr-ca": "L'Ontario"
        },
        "PE": {
          "en-ca": "Prince Edward Island",
          "fr-ca": "Île-du-Prince-Édouard"
        },
        "QC": {
          "en-ca": "Quebec",
          "fr-ca": "Le Québec"
        },
        "SK": {
          "en-ca": "Saskatchewan",
          "fr-ca": "La Saskatchewan"
        },
        "YT": {
          "en-ca": "Yukon",
          "fr-ca": "Le Yukon"
        }
      });

      _initializerDefineProperty(this, "contactName", _descriptor2, this);

      _initializerDefineProperty(this, "contactEmail", _descriptor3, this);

      _initializerDefineProperty(this, "name", _descriptor4, this);

      _initializerDefineProperty(this, "about", _descriptor5, this);

      _initializerDefineProperty(this, "website", _descriptor6, this);

      _initializerDefineProperty(this, "address1", _descriptor7, this);

      _initializerDefineProperty(this, "address2", _descriptor8, this);

      _initializerDefineProperty(this, "city", _descriptor9, this);

      _initializerDefineProperty(this, "province", _descriptor10, this);

      _initializerDefineProperty(this, "postal", _descriptor11, this);

      _initializerDefineProperty(this, "country", _descriptor12, this);

      _initializerDefineProperty(this, "phone", _descriptor13, this);

      _initializerDefineProperty(this, "feedback", _descriptor14, this);
    }

    async sendEmail() {
      if (this.contactName && this.contactEmail && this.name && this.about && this.website && this.address1 && this.city && this.province && this.postal && this.country && this.phone) {
        let url = _environment.default.apiServer.host + '/api/forms/join';
        let response = await fetch(url, {
          method: "POST",
          body: JSON.stringify({
            contact_Name: this.contactName,
            contact_Email: this.contactEmail,
            name: this.name,
            about: this.about,
            website: this.website,
            address1: this.address1,
            address2: this.address2,
            city: this.city,
            province: this.province,
            postal: this.postal,
            country: this.country,
            phone: this.phone
          }),
          credentials: 'same-origin',
          headers: this.store.adapterFor('application').headers
        });

        if (response.ok) {
          this.feedback = 'success';
        } else {
          this.feedback = 'error';
        }
      } else {
        this.feedback = 'error';
      }
    }

    selectProvince(event) {
      this.province = event.target.value;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "contactName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "contactEmail", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "about", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "website", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "address1", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "address2", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "city", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "province", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "postal", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "country", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Canada";
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "phone", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "feedback", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "sendEmail", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "sendEmail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectProvince", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "selectProvince"), _class.prototype)), _class));
  _exports.default = JoinController;
});