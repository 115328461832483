define("ccaa-frontend/routes/index/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IndexController = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember.computed('randomPartnersList'), (_class = class IndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "randomPartnersList", _descriptor2, this);
    }

    *pickRandomPartners() {
      do {
        let randomPartners = [];
        let randomIndex = null;

        if (this.randomPartnersList.length) {
          for (let i = 0; i < 8; i++) {
            if (this.model.partners.length - this.randomPartnersList.length > i) {
              do {
                randomIndex = Math.floor(Math.random() * this.model.partners.length);
              } while (this.randomPartnersList.indexOf(randomIndex) != -1 || randomPartners.indexOf(randomIndex) != -1);

              randomPartners.push(randomIndex);
            }
          }
        }

        for (let partnersRemaining = 8 - randomPartners.length; partnersRemaining > 0; partnersRemaining--) {
          if (randomPartners.length != this.model.partners.length) {
            do {
              randomIndex = Math.floor(Math.random() * this.model.partners.length);
            } while (randomPartners.indexOf(randomIndex) != -1);

            randomPartners.push(randomIndex);
          }
        }

        this.randomPartnersList = randomPartners;
        yield (0, _emberConcurrency.timeout)(5000);
      } while (this.model.partners.length > 8);
    }

    get homePartners() {
      let partners = [];
      let self = this;
      this.randomPartnersList.forEach(function (partnerIndex) {
        partners.push(self.model.partners.objectAt(partnerIndex));
      });
      return partners;
    }

    get sortedEvents() {
      let date = new Date();
      date.setDate(date.getDate() - 3);
      let events = this.model.events.filter(function (event) {
        return date.toISOString() <= event.startDate;
      });
      events = events.sortBy('startDate').reverse();
      return events.reverse();
    }

    get featuredArticle() {
      let filteredArticles = this.model.articles.filter(function (article) {
        return article.featured;
      });
      let filteredEvents = this.model.events.filter(function (event) {
        return event.featured;
      });

      if (filteredArticles.length || filteredEvents.length) {
        let sortedArticles = filteredArticles.sortBy('datePublished');
        sortedArticles = sortedArticles.reverse();
        let sortedEvents = filteredEvents.sortBy('startDate');
        sortedEvents = sortedEvents.reverse();

        if (filteredArticles.length && filteredEvents.length) {
          if (sortedArticles[0].datePublished > sortedEvents[0].datePublished) {
            return sortedArticles[0];
          } else {
            return sortedEvents[0];
          }
        } else if (filteredArticles.length) {
          return sortedArticles[0];
        } else if (filteredEvents.length) {
          return sortedEvents[0];
        }
      } else {
        return false;
      }
    }

    get newestArticle() {
      let filteredArticles = this.model.articles.filter(function (article) {
        return article.new;
      });

      if (filteredArticles.length) {
        let sortedArticles = filteredArticles.sortBy('datePublished');
        sortedArticles = sortedArticles.reverse();
        return sortedArticles[0];
      } else {
        return false;
      }
    }

    get spotlightArticles() {
      let filteredArticles = this.model.articles.filter(function (article) {
        return article.spotlight;
      });

      if (filteredArticles.length) {
        let sortedArticles = filteredArticles.sortBy('datePublished');
        sortedArticles = sortedArticles.reverse();

        if (sortedArticles.length == 1) {
          return [sortedArticles[0]];
        } else {
          return [sortedArticles[0], sortedArticles[1]];
        }
      } else {
        return false;
      }
    }

    get promoBoxArticle() {
      let filteredArticles = this.model.articles.filter(function (article) {
        return article.promo;
      });
      return filteredArticles[Math.floor(Math.random() * filteredArticles.length)];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "pickRandomPartners", [_emberConcurrency.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "pickRandomPartners"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "randomPartnersList", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "homePartners", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "homePartners"), _class.prototype)), _class));
  _exports.default = IndexController;
});