define("ccaa-frontend/routes/join/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class JoinRoute extends Ember.Route {}

  _exports.default = JoinRoute;
});