define("ccaa-frontend/routes/components/language-alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EpLIwm3a",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"well well--2 well--alert\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[8,\"fa-icon\",[],[[\"@icon\"],[\"thumbtack\"]],null],[2,\"Ce contenu n’est pas disponible en français. Toutes nos excuses.\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ccaa-frontend/routes/components/language-alert/template.hbs"
    }
  });

  _exports.default = _default;
});