define("ccaa-frontend/routes/search/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SearchController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember.computed('query'), (_class = class SearchController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _defineProperty(this, "queryParams", ['query']);

      _defineProperty(this, "provinces", {
        "AB": {
          "en-ca": "Alberta",
          "fr-ca": "L'Alberta"
        },
        "BC": {
          "en-ca": "British Columbia",
          "fr-ca": "La Colombie-Britannique"
        },
        "MB": {
          "en-ca": "Manitoba",
          "fr-ca": "Le Manitoba"
        },
        "NB": {
          "en-ca": "New Brunswick",
          "fr-ca": "Le Nouveau-Brunswick"
        },
        "NL": {
          "en-ca": "Newfoundland and Labrador",
          "fr-ca": "La Terre-Neuve-et-Labrador"
        },
        "NT": {
          "en-ca": "Northwest Territories",
          "fr-ca": "Les Territoires du Nord-Ouest"
        },
        "NS": {
          "en-ca": "Nova Scotia",
          "fr-ca": "La Nouvelle-Écosse"
        },
        "NU": {
          "en-ca": "Nunavut",
          "fr-ca": "Le Nunavut"
        },
        "ON": {
          "en-ca": "Ontario",
          "fr-ca": "L'Ontario"
        },
        "PE": {
          "en-ca": "Prince Edward Island",
          "fr-ca": "Île-du-Prince-Édouard"
        },
        "QC": {
          "en-ca": "Quebec",
          "fr-ca": "Le Québec"
        },
        "SK": {
          "en-ca": "Saskatchewan",
          "fr-ca": "La Saskatchewan"
        },
        "YT": {
          "en-ca": "Yukon",
          "fr-ca": "Le Yukon"
        }
      });

      _initializerDefineProperty(this, "query", _descriptor3, this);
    }

    get searchResults() {
      let regexExpression = "(?i)(" + this.query + ")";
      let promiseArray = [];
      promiseArray.push(this.store.query('news-article', {
        "status": "active",
        "$or": [{
          [`data.title.${this.intl.primaryLocale}`]: {
            "$regex": regexExpression
          }
        }, {
          [`data.subheader.${this.intl.primaryLocale}`]: {
            "$regex": regexExpression
          }
        }, {
          "data.author": {
            "$regex": regexExpression
          }
        }, {
          [`data.content.${this.intl.primaryLocale}`]: {
            "$elemMatch": {
              "$or": [{
                "type": "heading",
                "title": {
                  "$regex": regexExpression
                }
              }, {
                "type": "text",
                "content": {
                  "$regex": regexExpression
                }
              }]
            }
          }
        }]
      }));
      promiseArray.push(this.store.query('event', {
        "status": "active",
        "$or": [{
          [`data.name.${this.intl.primaryLocale}`]: {
            "$regex": regexExpression
          }
        }, {
          "data.address": {
            "$elemMatch": {
              "$or": [{
                "address.address1": {
                  "$regex": regexExpression
                }
              }, {
                "address.address2": {
                  "$regex": regexExpression
                }
              }, {
                "address.city": {
                  "$regex": regexExpression
                }
              }, {
                "address.state": {
                  "$regex": regexExpression
                }
              }, {
                "address.country": {
                  "$regex": regexExpression
                }
              }]
            }
          }
        }, {
          [`data.description.${this.intl.primaryLocale}`]: {
            "$elemMatch": {
              "$or": [{
                "type": "heading",
                "title": {
                  "$regex": regexExpression
                }
              }, {
                "type": "text",
                "content": {
                  "$regex": regexExpression
                }
              }]
            }
          }
        }]
      }));
      promiseArray.push(this.store.query('career', {
        "status": "active",
        "$or": [{
          [`data.name.${this.intl.primaryLocale}`]: {
            "$regex": regexExpression
          }
        }, {
          [`data.description.${this.intl.primaryLocale}`]: {
            "$regex": regexExpression
          }
        }, {
          [`data.careerPathway.${this.intl.primaryLocale}`]: {
            "$elemMatch": {
              "text": {
                "$regex": regexExpression
              }
            }
          }
        }, {
          [`data.requirements.${this.intl.primaryLocale}`]: {
            "$elemMatch": {
              "text": {
                "$regex": regexExpression
              }
            }
          }
        }, {
          [`data.jobTitles.${this.intl.primaryLocale}`]: {
            "$elemMatch": {
              "text": {
                "$regex": regexExpression
              }
            }
          }
        }, {
          [`data.employmentOpportunities.${this.intl.primaryLocale}`]: {
            "$elemMatch": {
              "text": {
                "$regex": regexExpression
              }
            }
          }
        }]
      }));
      promiseArray.push(this.store.query('job-posting', {
        "status": "active",
        "$or": [{
          [`data.name.${this.intl.primaryLocale}`]: {
            "$regex": regexExpression
          }
        }, {
          "data.company": {
            "$regex": regexExpression
          }
        }, {
          "data.city": {
            "$regex": regexExpression
          }
        }, {
          "data.province": {
            "$regex": regexExpression
          }
        }]
      }));
      promiseArray.push(this.store.query('training-course', {
        "status": "active",
        "$or": [{
          [`data.name.${this.intl.primaryLocale}`]: {
            "$regex": regexExpression
          }
        }, {
          [`data.type.${this.intl.primaryLocale}`]: {
            "$regex": regexExpression
          }
        }, {
          [`data.description.${this.intl.primaryLocale}`]: {
            "$elemMatch": {
              "$or": [{
                "type": "heading",
                "title": {
                  "$regex": regexExpression
                }
              }, {
                "type": "text",
                "content": {
                  "$regex": regexExpression
                }
              }]
            }
          }
        }, {
          [`data.testimonials.${this.intl.primaryLocale}`]: {
            "$elemMatch": {
              "$or": [{
                "testimonial": {
                  "$regex": regexExpression
                }
              }, {
                "name": {
                  "$regex": regexExpression
                }
              }]
            }
          }
        }, {
          [`data.attendees.${this.intl.primaryLocale}`]: {
            "$elemMatch": {
              "text": {
                "$regex": regexExpression
              }
            }
          }
        }]
      }));
      promiseArray.push(this.store.query('faq', {
        "$or": [{
          [`data.question.${this.intl.primaryLocale}`]: {
            "$regex": regexExpression
          }
        }, {
          [`data.answer.${this.intl.primaryLocale}`]: {
            "$regex": regexExpression
          }
        }, {
          [`data.type.${this.intl.primaryLocale}`]: {
            "$regex": regexExpression
          }
        }]
      }));
      promiseArray.push(this.store.query('standard', {
        "$or": [{
          [`data.name.${this.intl.primaryLocale}`]: {
            "$regex": regexExpression
          }
        }, {
          [`data.description.${this.intl.primaryLocale}`]: {
            "$regex": regexExpression
          }
        }, {
          [`data.relatedLinks.${this.intl.primaryLocale}`]: {
            "$elemMatch": {
              "text": {
                "$regex": regexExpression
              }
            }
          }
        }]
      }));
      promiseArray.push(this.store.query('rfp', {
        "$or": [{
          [`data.title.${this.intl.primaryLocale}`]: {
            "$regex": regexExpression
          }
        }, {
          [`data.description.${this.intl.primaryLocale}`]: {
            "$regex": regexExpression
          }
        }]
      }));
      promiseArray.push(this.store.query('educator-link', {
        [`text.${this.intl.primaryLocale}`]: {
          "$regex": regexExpression
        }
      }));
      return Ember.RSVP.all(promiseArray).then(function (values) {
        let newsArticles = values[0];
        newsArticles = newsArticles.sortBy('datePublished');
        newsArticles = newsArticles.reverse();
        let events = values[1];
        events = events.sortBy('startDate');
        events = events.reverse();
        let jobs = values[3];
        jobs = jobs.sortBy('datePosted');
        jobs = jobs.reverse();
        let faqs = values[5];
        faqs = faqs.sortBy('type');
        return {
          newsArticles: newsArticles,
          events: events,
          careers: values[2],
          jobs: jobs,
          training: values[4],
          standards: values[6],
          faqs: faqs,
          rfps: values[7],
          educatorLinks: values[8]
        };
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "query", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "searchResults", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "searchResults"), _class.prototype)), _class));
  _exports.default = SearchController;
});