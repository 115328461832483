define("ccaa-frontend/routes/news/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let NewsIndexController = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember.computed('typeFilter', 'tagFilter'), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class NewsIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['page', 'typeFilter']);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "typeFilter", _descriptor2, this);

      _initializerDefineProperty(this, "tagFilter", _descriptor3, this);

      _initializerDefineProperty(this, "page", _descriptor4, this);

      _initializerDefineProperty(this, "allDocsLength", _descriptor5, this);

      _defineProperty(this, "viewRoutes", {
        "news-article": "news.view",
        "event": "events.view",
        "workshop": "workshops.view"
      });
    }

    get currentDocs() {
      let self = this;
      let docs = [];
      docs = docs.pushObjects(this.model.articles.toArray());
      docs = docs.pushObjects(this.model.events.toArray());

      if (this.typeFilter != 'all') {
        docs = docs.filter(function (doc) {
          return doc.constructor.modelName == self.typeFilter;
        });
      }

      if (this.tagFilter) {
        docs = docs.filter(function (doc) {
          let flag = false;

          if (self.tagFilter == "workshop") {
            flag = doc.workshop;
          } else {
            doc.tags.forEach(function (tag) {
              if (tag.name[self.intl.primaryLocale] == self.tagFilter) {
                flag = true;
              }
            });
          }

          return flag;
        });
      }

      docs = docs.map(function (doc) {
        if (doc.constructor.modelName == 'news-article') {
          doc.date = doc.datePublished;
        } else if (doc.constructor.modelName == 'event') {
          doc.date = doc.startDate;
        }

        return doc;
      });
      this.allDocsLength = docs.length;
      docs = docs.sortBy('date');
      docs = docs.reverse();

      if (this.typeFilter == 'event') {
        docs = docs.reverse();
      }

      return docs.slice(this.page * 10 - 10, this.page * 10);
    }

    get paginationNumbers() {
      let numbers = []; //first pagination number

      if (this.allDocsLength <= 30 || this.page == 1 || this.page == 2) {
        numbers.push(1);
      } else if (this.page * 10 > this.allDocsLength) {
        numbers.push(this.page - 2);
      } else {
        numbers.push(this.page - 1);
      } //second pagination number


      if (this.allDocsLength <= 10) {//number array should be 2 shorter
      } else if (this.page == 1) {
        numbers.push(2);
      } else if (this.page * 10 > this.allDocsLength) {
        numbers.push(this.page - 1);
      } else {
        numbers.push(this.page);
      } //third pagination number


      if (this.allDocsLength <= 20) {//number array should be 1 shorter
      } else if (this.page == 1) {
        numbers.push(3);
      } else if (this.page * 10 > this.allDocsLength) {
        numbers.push(this.page);
      } else {
        numbers.push(this.page + 1);
      }

      return numbers;
    }

    get tagList() {
      let self = this;
      let tagList = [];
      let docs = [];

      if (this.typeFilter == 'news-article') {
        docs = this.model.articles;
      } else if (this.typeFilter == 'event') {
        docs = this.model.events;
      }

      docs.forEach(function (doc) {
        doc.tags.forEach(function (tag) {
          let tagName = tag.name[self.intl.primaryLocale];

          if (tagList.indexOf(tagName) == -1) {
            tagList.push(tagName);
          }
        });
      });
      return tagList;
    }

    selectTypeFilter(type) {
      this.page = 1;
      this.tagFilter = "";
      this.typeFilter = type;
    }

    selectTypeFilterMobile(event) {
      this.page = 1;
      this.tagFilter = "";
      this.typeFilter = event.target.value;
    }

    selectTagFilter(tag) {
      this.page = 1;

      if (tag == this.tagFilter) {
        this.tagFilter = "";
      } else {
        this.tagFilter = tag;
      }
    }

    selectTagFilterMobile(event) {
      this.page = 1;

      if (event.target.value == this.tagFilter) {
        this.tagFilter = "";
      } else {
        this.tagFilter = event.target.value;
      }
    }

    onClickNextPage(direction) {
      if (direction == "next") {
        if (this.page * 10 < this.allDocsLength) {
          this.page += 1;
        }
      } else if (direction == "previous") {
        if (this.page > 1) {
          this.page -= 1;
        }
      }
    }

    onClickPaginationNumber(number) {
      this.page = number;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "typeFilter", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "all";
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tagFilter", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "allDocsLength", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "paginationNumbers", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "paginationNumbers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectTypeFilter", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "selectTypeFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectTypeFilterMobile", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "selectTypeFilterMobile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectTagFilter", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "selectTagFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectTagFilterMobile", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "selectTagFilterMobile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickNextPage", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onClickNextPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickPaginationNumber", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onClickPaginationNumber"), _class.prototype)), _class));
  _exports.default = NewsIndexController;
});