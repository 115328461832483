define("ccaa-frontend/routes/training-pages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TmfqXKM8",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Training Page\"],null]],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ccaa-frontend/routes/training-pages/template.hbs"
    }
  });

  _exports.default = _default;
});