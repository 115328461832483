define("ccaa-frontend/routes/subsidies/swpp/student-form/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SubsidiesSwppStudentFormRoute extends Ember.Route {}

  _exports.default = SubsidiesSwppStudentFormRoute;
});