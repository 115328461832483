define("ccaa-frontend/routes/training/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jW9je28M",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[30,[36,0],[\"training.index.page-title\"],null]],null]],[2,\"\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"page-title\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ccaa-frontend/routes/training/template.hbs"
    }
  });

  _exports.default = _default;
});