define("ccaa-frontend/routes/privacy-policy/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PrivacyPolicyRoute extends Ember.Route {}

  _exports.default = PrivacyPolicyRoute;
});