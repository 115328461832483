define("ccaa-frontend/routes/swpp/student-form/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SwppStudentFormRoute extends Ember.Route {}

  _exports.default = SwppStudentFormRoute;
});