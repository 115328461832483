define("ccaa-frontend/initializers/ember-anchor", ["exports", "ccaa-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    const application = arguments[1] || arguments[0];
    const {
      emberAnchor
    } = _environment.default;
    application.register('config:ember-anchor', emberAnchor, {
      instantiate: false
    });
  }

  var _default = {
    name: 'ember-anchor',
    initialize: initialize
  };
  _exports.default = _default;
});