define("ccaa-frontend/routes/careers-and-jobs/view/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CareersAndJobsViewRoute extends Ember.Route {}

  _exports.default = CareersAndJobsViewRoute;
});