define("ccaa-frontend/routes/components/embedded-document/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EmbeddedDocumentComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class EmbeddedDocumentComponent extends _component.default {
    constructor() {
      super(...arguments); // custom paths should begin with "contentWindow"

      _initializerDefineProperty(this, "iframeElement", _descriptor, this);

      this.observeElementPath = this.args.observeElementPath || "contentWindow.document.documentElement.lastChild.firstChild";
      this.heightBuffer = parseInt(this.args.heightBuffer) || 0;
      this.eventListeners = this.args.eventListeners || ["click", "wheel", "touchmove"];
    }

    onLoadIframe(obj) {
      this.iframeElement = obj.target;
      this.resizeIframe(obj);
      this.eventListeners.forEach(event => {
        obj.target.contentWindow.addEventListener(event, this.resizeIframe);
      });
    }

    resizeIframe(obj) {
      // observe-resize addon (triggered when viewport size changes) passes an array
      if (Array.isArray(obj)) {
        obj = obj[0];
      }

      let elementPath = this.eventListeners.includes(obj.type) ? this.observeElementPath.replace("contentWindow", "view") : `target.${this.observeElementPath}`;
      let element = Ember.get(obj, elementPath);

      if (element && this.iframeElement) {
        // create short delay to give the iframe contents time to respond to certain actions, such as "click"
        Ember.run.later(this, function () {
          let height = element.scrollHeight + this.heightBuffer;
          this.iframeElement.style.minHeight = `${height}px`;
        }, 50);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "iframeElement", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onLoadIframe", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onLoadIframe"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resizeIframe", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "resizeIframe"), _class.prototype)), _class));
  _exports.default = EmbeddedDocumentComponent;
});