define("ccaa-frontend/routes/search/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SearchRoute = (_dec = Ember.inject.service, (_class = class SearchRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);
    }
    /*
    async model(params) {
      let regexExpression = "(?i)(" + params.query + ")"
       let newsArticles = await this.store.query('news-article', {
        "status": "active",
        "$or": [
          { [`data.title.${this.intl.primaryLocale}`]: {"$regex": regexExpression} },
          { [`data.subheader.${this.intl.primaryLocale}`]: {"$regex": regexExpression} },
          { "data.author": {"$regex": regexExpression} },
          { [`data.content.${this.intl.primaryLocale}`]: {
            "$elemMatch": {
              "$or": [
                { "type": "heading", "title": {"$regex": regexExpression} },
                { "type": "text",  "content": {"$regex": regexExpression} }
              ]
            }
          } }
        ]
      });
      newsArticles = newsArticles.sortBy('datePublished');
      newsArticles = newsArticles.reverse();
       let events = await this.store.query('event', {
        "status": "active",
        "$or": [
          { [`data.name.${this.intl.primaryLocale}`]: {"$regex": regexExpression} },
          { "data.address": {
            "$elemMatch": {
              "$or": [
                { "address.address1": {"$regex": regexExpression} },
                { "address.address2": {"$regex": regexExpression} },
                { "address.city": {"$regex": regexExpression} },
                { "address.state": {"$regex": regexExpression} },
                { "address.country": {"$regex": regexExpression} }
              ]
            }
          } },
          { [`data.description.${this.intl.primaryLocale}`]: {
            "$elemMatch": {
              "$or": [
                { "type": "heading", "title": {"$regex": regexExpression} },
                { "type": "text",  "content": {"$regex": regexExpression} }
              ]
            }
          } }
        ]
      });
      events = events.sortBy('startDate');
      events = events.reverse();
       let careers = await this.store.query('career', {
        "status": "active",
        "$or": [
          { [`data.name.${this.intl.primaryLocale}`]: {"$regex": regexExpression} },
          { [`data.description.${this.intl.primaryLocale}`]: {"$regex": regexExpression} },
          { [`data.careerPathway.${this.intl.primaryLocale}`]: {
            "$elemMatch": {
              "text": {"$regex": regexExpression}
            }
          } },
          { [`data.requirements.${this.intl.primaryLocale}`]: {
            "$elemMatch": {
              "text": {"$regex": regexExpression}
            }
          } },
          { [`data.jobTitles.${this.intl.primaryLocale}`]: {
            "$elemMatch": {
              "text": {"$regex": regexExpression}
            }
          } },
          { [`data.employmentOpportunities.${this.intl.primaryLocale}`]: {
            "$elemMatch": {
              "text": {"$regex": regexExpression}
            }
          } }
        ]
      });
       let training = await this.store.query('training-course', {
        "status": "active",
        "$or": [
          { [`data.name.${this.intl.primaryLocale}`]: {"$regex": regexExpression} },
          { [`data.type.${this.intl.primaryLocale}`]: {"$regex": regexExpression} },
          { [`data.description.${this.intl.primaryLocale}`]: {
            "$elemMatch": {
              "$or": [
                { "type": "heading", "title": {"$regex": regexExpression} },
                { "type": "text",  "content": {"$regex": regexExpression} }
              ]
            }
          } },
          { [`data.testimonials.${this.intl.primaryLocale}`]: {
            "$elemMatch": {
              "$or": [
                { "testimonial": {"$regex": regexExpression} },
                { "name": {"$regex": regexExpression} }
              ]
            }
          } },
          { [`data.attendees.${this.intl.primaryLocale}`]: {
            "$elemMatch": {
              "text": {"$regex": regexExpression}
            }
          } }
        ]
      });
       let faqs = await this.store.query('faq', {
        "$or": [
          { [`data.question.${this.intl.primaryLocale}`]: {"$regex": regexExpression} },
          { [`data.answer.${this.intl.primaryLocale}`]: {"$regex": regexExpression} },
          { [`data.type.${this.intl.primaryLocale}`]: {"$regex": regexExpression} }
        ]
      });
      faqs = faqs.sortBy('type');
       let standards = await this.store.query('standard', {
        "$or": [
          { [`data.name.${this.intl.primaryLocale}`]: {"$regex": regexExpression} },
          { [`data.description.${this.intl.primaryLocale}`]: {"$regex": regexExpression} },
          { [`data.relatedLinks.${this.intl.primaryLocale}`]: {
            "$elemMatch": {
              "text": {"$regex": regexExpression}
            }
          } }
        ]
      });
       let rfps = await this.store.query('rfp', {
        "$or": [
          { [`data.title.${this.intl.primaryLocale}`]: {"$regex": regexExpression} },
          { [`data.description.${this.intl.primaryLocale}`]: {"$regex": regexExpression} }
        ]
      });
       let educatorLinks = await this.store.query('educator-link', {
        [`text.${this.intl.primaryLocale}`]: {"$regex": regexExpression}
      });
       return { newsArticles: newsArticles, events: events, careers: careers, training: training, standards: standards, faqs: faqs, rfps: rfps, educatorLinks: educatorLinks }
    }
    */


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SearchRoute;
});