define("ccaa-frontend/routes/contact/controller", ["exports", "ccaa-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ContactController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class ContactController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "companyName", _descriptor3, this);

      _initializerDefineProperty(this, "name", _descriptor4, this);

      _initializerDefineProperty(this, "email", _descriptor5, this);

      _initializerDefineProperty(this, "phone", _descriptor6, this);

      _initializerDefineProperty(this, "information", _descriptor7, this);

      _initializerDefineProperty(this, "comments", _descriptor8, this);

      _initializerDefineProperty(this, "feedback", _descriptor9, this);
    }

    selectInformation(event) {
      this.information = event.target.value;
    }

    async sendEmail() {
      if (this.email && this.companyName && this.name && this.phone && this.information && this.comments) {
        let url = _environment.default.apiServer.host + '/api/forms/contact';
        let response = await fetch(url, {
          method: "POST",
          body: JSON.stringify({
            companyName: this.companyName,
            name: this.name,
            email: this.email,
            phone: this.phone,
            information: this.information,
            comments: this.comments
          }),
          credentials: 'same-origin',
          headers: this.store.adapterFor('application').headers
        });

        if (response.ok) {
          this.feedback = 'success';
        } else {
          this.feedback = 'error';
        }
      } else {
        this.feedback = 'error';
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "companyName", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "email", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "phone", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "information", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "General Information";
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "comments", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "feedback", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectInformation", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "selectInformation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendEmail", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "sendEmail"), _class.prototype)), _class));
  _exports.default = ContactController;
});