define("ccaa-frontend/routes/certification/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CertificationIndexRoute extends Ember.Route {
    async model() {
      let faqs = await this.store.query('faq', {
        type: "certification"
      });
      let standards = await this.store.findAll('standard');
      return {
        faqs: faqs,
        standards: standards
      };
    }

  }

  _exports.default = CertificationIndexRoute;
});