define("ccaa-frontend/routes/training/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TrainingIndexRoute extends Ember.Route {
    async model() {
      let instructors = await this.store.query('instructor', {
        status: "active"
      });
      let courses = await this.store.query('training-course', {
        status: "active"
      });
      let faqs = await this.store.query('faq', {
        type: "training"
      });
      let articles = await this.store.query('training-page', {
        status: "active"
      });
      return {
        instructors: instructors,
        courses: courses,
        faqs: faqs,
        articles: articles
      };
    }

  }

  _exports.default = TrainingIndexRoute;
});