define("ccaa-frontend/routes/careers-and-jobs/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CareersAndJobsIndexRoute extends Ember.Route {
    async model() {
      let jobs = await this.store.query('job-posting', {
        status: "active"
      });
      let careers = await this.store.findAll('career');
      return {
        jobs: jobs,
        careers: careers
      };
    }

  }

  _exports.default = CareersAndJobsIndexRoute;
});