define("ccaa-frontend/routes/application/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApplicationController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class ApplicationController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "moment", _descriptor2, this);

      _initializerDefineProperty(this, "searchQuery", _descriptor3, this);

      _initializerDefineProperty(this, "companyNoticeVisible", _descriptor4, this);

      _initializerDefineProperty(this, "siteIndicatorVisible", _descriptor5, this);

      _initializerDefineProperty(this, "mobileNavigationVisible", _descriptor6, this);

      _initializerDefineProperty(this, "mobileWebsiteSearchVisible", _descriptor7, this);
    }

    toggleCompanyNotice() {
      this.companyNoticeVisible = !this.companyNoticeVisible;
    }

    toggleSiteIndicator() {
      this.siteIndicatorVisible = !this.siteIndicatorVisible;
    }

    toggleMobileNav() {
      this.mobileNavigationVisible = !this.mobileNavigationVisible;
    }

    toggleMobileWebsiteSearch() {
      this.mobileWebsiteSearchVisible = !this.mobileWebsiteSearchVisible;
    }

    toggleLocale() {
      if (this.intl.primaryLocale == "en-ca") {
        this.intl.setLocale(['fr-ca']);
        this.moment.setLocale('fr');
      } else if (this.intl.primaryLocale == "fr-ca") {
        this.intl.setLocale(['en-ca']);
        this.moment.setLocale('en');
      }
    }

    search(event) {
      event.preventDefault();
      let query = this.searchQuery;
      this.searchQuery = "";
      this.transitionToRoute('search', {
        queryParams: {
          query: query
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "moment", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "searchQuery", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "companyNoticeVisible", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "siteIndicatorVisible", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return window.location ? window.location.href.includes("staging.torusoft.com") : false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "mobileNavigationVisible", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "mobileWebsiteSearchVisible", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleCompanyNotice", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCompanyNotice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleSiteIndicator", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSiteIndicator"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleMobileNav", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "toggleMobileNav"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleMobileWebsiteSearch", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "toggleMobileWebsiteSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleLocale", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "toggleLocale"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "search", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype)), _class));
  _exports.default = ApplicationController;
});