define("ccaa-frontend/routes/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IndexRoute extends Ember.Route {
    async model() {
      let banners = await this.store.query('banner', {
        status: "active"
      });
      let articles = await this.store.query('news-article', {
        status: "active"
      });
      let partners = await this.store.findAll('partner');
      let events = await this.store.query('event', {
        status: "active"
      });
      return {
        banners: banners,
        articles: articles,
        partners: partners,
        events: events
      };
    }

  }

  _exports.default = IndexRoute;
});