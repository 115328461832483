define("ccaa-frontend/routes/disclaimer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BesOZ02Y",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[30,[36,0],[\"disclaimer.page-title\"],null]],null]],[2,\"\\n\"],[10,\"section\"],[14,0,\"hero hero--static\"],[12],[2,\"\\n    \"],[10,\"picture\"],[14,0,\"hero__picture\"],[12],[2,\"\\n        \"],[10,\"img\"],[14,0,\"hero__img\"],[14,\"src\",\"/assets/images/test-hero-subpage.jpg\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hero-content text--on-dark\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"grid grid-pad hero-content-grid\"],[12],[2,\"\\n            \"],[8,\"link-to\",[[24,0,\"hero-content__back\"]],[[\"@route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[8,\"fa-icon\",[[24,0,\"hero-content__back-icon\"]],[[\"@icon\"],[\"chevron-left\"]],null],[1,[30,[36,0],[\"disclaimer.back-button\"],null]]],\"parameters\":[]}]]],[2,\"\\n            \"],[10,\"h1\"],[14,0,\"header-bold header-bold--1 hero-content__header\"],[12],[1,[30,[36,0],[\"disclaimer.header\"],null]],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"flourish\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"section\"],[14,0,\"privacy-policy-body\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"grid grid-pad\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-8-12 mobile-col-1-1 clearfix\"],[12],[2,\"\\n            \"],[10,\"p\"],[14,0,\"body body--1\"],[12],[1,[30,[36,0],[\"disclaimer.text-1\"],null]],[13],[2,\"\\n            \"],[10,\"p\"],[14,0,\"body body--1\"],[12],[1,[30,[36,0],[\"disclaimer.text-2\"],null]],[13],[2,\"\\n            \"],[10,\"p\"],[14,0,\"body body--1\"],[12],[1,[30,[36,0],[\"disclaimer.text-3\"],null]],[13],[2,\"\\n            \"],[10,\"p\"],[14,0,\"body body--1\"],[12],[1,[30,[36,0],[\"disclaimer.text-4\"],null]],[13],[2,\"\\n\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"page-title\"]}",
    "meta": {
      "moduleName": "ccaa-frontend/routes/disclaimer/template.hbs"
    }
  });

  _exports.default = _default;
});