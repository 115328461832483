define("ccaa-frontend/helpers/truncate-rich-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function truncateRichContent([content, length]) {
    let richText = "";
    content.forEach(function (element) {
      if (element.type == "heading") {
        richText += " ";
        richText += "<strong>" + element.title + "</strong>";
      }

      if (element.type == "text") {
        richText += " ";
        let string = element.content.replace(/(<([^>]+)>)/gi, "");
        richText += string;
      }
    });
    return richText.substring(0, length);
  });

  _exports.default = _default;
});