define("ccaa-frontend/routes/news/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class NewsRoute extends Ember.Route {}

  _exports.default = NewsRoute;
});