define("ccaa-frontend/routes/careers-and-jobs/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CareersAndJobsRoute extends Ember.Route {}

  _exports.default = CareersAndJobsRoute;
});