define("ccaa-frontend/routes/news/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class NewsIndexRoute extends Ember.Route {
    async model() {
      let articles = await this.store.query('news-article', {
        status: "active"
      });
      let events = await this.store.query('event', {
        status: "active"
      });
      let date = new Date();
      date.setDate(date.getDate() - 3);
      events = events.filter(function (event) {
        return date.toISOString() <= event.startDate;
      });
      return {
        articles: articles,
        events: events
      };
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.page = 1;
    }

  }

  _exports.default = NewsIndexRoute;
});