define("ccaa-frontend/routes/training-pages/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TrainingPagesRoute extends Ember.Route {}

  _exports.default = TrainingPagesRoute;
});