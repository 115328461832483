define("ccaa-frontend/routes/news/view/route", ["exports", "ccaa-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let NewsViewRoute = (_dec = Ember.inject.service, (_class = class NewsViewRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);
    }

    async model(params) {
      let article = await this.store.queryRecord('news-article', {
        slug: params.slug
      });

      if (!article) {
        throw "not-found";
      }

      return article;
    }

    headTags() {
      let model = this.modelFor(this.routeName);
      let image;

      if (model.coverImage) {
        image = _environment.default.apiServer.host + '/api/news-articles/' + model.id + '/attachments/' + model.coverImage;
      } else {
        image = _environment.default.apiServer.host + "/assets/images/ccaa-logos.jpg";
      }

      if (model) {
        return [{
          type: 'meta',
          attrs: {
            property: 'og:title',
            content: model.title[this.intl.primaryLocale]
          }
        }, {
          type: 'meta',
          attrs: {
            property: 'og:type',
            content: 'website'
          }
        }, {
          type: 'meta',
          attrs: {
            property: 'og:image',
            content: image
          }
        }, {
          type: 'meta',
          attrs: {
            property: 'og:description',
            content: "Check out the CCAA website for more news articles."
          }
        }];
      } else {
        return [];
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = NewsViewRoute;
});