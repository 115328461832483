define("ccaa-frontend/router", ["exports", "ccaa-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('partners');
    this.route('contact');
    this.route('rfp');
    this.route('about');
    this.route('login');
    this.route('news', function () {
      this.route('view', {
        path: '/profile/:slug'
      });
    });
    this.route('events', function () {
      this.route('view', {
        path: '/profile/:slug'
      });
    });
    this.route('careers-and-jobs', function () {
      this.route('view');
    });
    this.route('careers', function () {
      this.route('view', {
        path: '/profile/:slug'
      });
    });
    this.route('training', function () {
      this.route('workshop', {
        path: '/profile/:slug'
      });
    });
    this.route('training-pages', function () {
      this.route('view', {
        path: '/:slug'
      });
    });
    this.route('certification', function () {
      this.route('standard', {
        path: '/standard/:slug'
      });
    });
    this.route('swpp', function () {
      this.route('employer-form');
      this.route('student-form');
    });
    this.route('industry');
    this.route('employers');
    this.route('educators');
    this.route('privacy-policy');
    this.route('disclaimer');
    this.route('join');
    this.route('404', {
      path: ':wildcard'
    });
    this.route('search');
    this.route('subsidies', function () {
      this.route('swpp', function () {
        this.route('employer-form');
        this.route('student-form');
      });
    });
    this.route('en', function () {
      this.route('swpp-student');
    });
  });
});