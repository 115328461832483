define("ccaa-frontend/routes/login/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LoginRoute extends Ember.Route {
    async model() {
      let articles = await this.store.query('news-article', {
        status: "active"
      });
      let events = await this.store.query('event', {
        status: "active"
      });
      return {
        articles: articles,
        events: events
      };
    }

  }

  _exports.default = LoginRoute;
});