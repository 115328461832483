define("ccaa-frontend/routes/careers/view/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CareersViewRoute extends Ember.Route {
    async model(params) {
      let career = await this.store.queryRecord('career', {
        slug: params.slug
      });

      if (!career) {
        throw "not-found";
      }

      return career;
    }

  }

  _exports.default = CareersViewRoute;
});