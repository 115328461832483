define("ccaa-frontend/routes/certification/standard/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CertificationStandardRoute extends Ember.Route {
    async model(params) {
      let standard = await this.store.queryRecord('standard', {
        slug: params.slug
      });

      if (!standard) {
        throw "not-found";
      }

      return standard;
    }

  }

  _exports.default = CertificationStandardRoute;
});