define("ccaa-frontend/routes/components/embedded-document/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7XqIkJes",
    "block": "{\"symbols\":[\"@class\",\"@title\",\"@src\"],\"statements\":[[11,\"iframe\"],[16,0,[32,1]],[16,\"title\",[32,2]],[24,\"scrolling\",\"no\"],[16,\"src\",[32,3]],[4,[38,0],[[32,0,[\"resizeIframe\"]]],null],[4,[38,1],[\"load\",[32,0,[\"onLoadIframe\"]]],null],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"observe-resize\",\"on\"]}",
    "meta": {
      "moduleName": "ccaa-frontend/routes/components/embedded-document/template.hbs"
    }
  });

  _exports.default = _default;
});