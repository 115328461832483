define("ccaa-frontend/routes/subsidies/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SubsidiesRoute extends Ember.Route {}

  _exports.default = SubsidiesRoute;
});