define("ccaa-frontend/routes/components/sub-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mbYyfiqC",
    "block": "{\"symbols\":[\"category\",\"index\",\"@categories\",\"@static\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"sub-page-nav-block \",[30,[36,4],[[32,4],\"sub-page-nav-block--static\"],null]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"grid grid-pad padding--top-none padding--bottom-none\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-1-1\"],[12],[2,\"\\n            \"],[11,\"button\"],[24,0,\"sub-page-nav-toggle\"],[4,[38,5],[\"click\",[32,0,[\"toggleSubPageNav\"]]],null],[12],[1,[30,[36,1],[\"components.sub-nav.skip\"],null]],[8,\"fa-icon\",[[24,0,\"sub-page-nav-toggle__icon\"]],[[\"@icon\"],[\"sort-down\"]],null],[13],[2,\"\\n            \"],[10,\"nav\"],[15,0,[31,[\"sub-page-nav \",[30,[36,4],[[32,0,[\"mobileSubPageNavigationVisible\"]],\"sub-page-nav--visible\"],null]]]],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[10,\"a\"],[15,6,[31,[\"#\",[32,1]]]],[14,0,\"sub-page-nav__link\"],[12],[1,[30,[36,1],[[30,[36,0],[\"components.sub-nav.\",[32,1]],null]],null]],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,3,[\"length\"]],[30,[36,2],[[32,2],1],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                        \"],[10,\"span\"],[14,0,\"sub-page-nav__bull\"],[12],[2,\"•\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"t\",\"add\",\"not-eq\",\"if\",\"on\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ccaa-frontend/routes/components/sub-nav/template.hbs"
    }
  });

  _exports.default = _default;
});