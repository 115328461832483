define("ccaa-frontend/transforms/attachment", ["exports", "ember-torusoft-api-adapter/transforms/attachment"], function (_exports, _attachment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _attachment.default;
    }
  });
});