define("ccaa-frontend/routes/careers-and-jobs/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CareersAndJobsIndexController = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class CareersAndJobsIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['category']);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "calendarBuffer", _descriptor2, this);

      _initializerDefineProperty(this, "center", _descriptor3, this);

      _initializerDefineProperty(this, "selectedDate", _descriptor4, this);

      _initializerDefineProperty(this, "selectedProvince", _descriptor5, this);

      _initializerDefineProperty(this, "category", _descriptor6, this);

      _defineProperty(this, "provinces", {
        "AB": {
          "en-ca": "Alberta",
          "fr-ca": "L'Alberta"
        },
        "BC": {
          "en-ca": "British Columbia",
          "fr-ca": "La Colombie-Britannique"
        },
        "MB": {
          "en-ca": "Manitoba",
          "fr-ca": "Le Manitoba"
        },
        "NB": {
          "en-ca": "New Brunswick",
          "fr-ca": "Le Nouveau-Brunswick"
        },
        "NL": {
          "en-ca": "Newfoundland and Labrador",
          "fr-ca": "La Terre-Neuve-et-Labrador"
        },
        "NT": {
          "en-ca": "Northwest Territories",
          "fr-ca": "Les Territoires du Nord-Ouest"
        },
        "NS": {
          "en-ca": "Nova Scotia",
          "fr-ca": "La Nouvelle-Écosse"
        },
        "NU": {
          "en-ca": "Nunavut",
          "fr-ca": "Le Nunavut"
        },
        "ON": {
          "en-ca": "Ontario",
          "fr-ca": "L'Ontario"
        },
        "PE": {
          "en-ca": "Prince Edward Island",
          "fr-ca": "Île-du-Prince-Édouard"
        },
        "QC": {
          "en-ca": "Quebec",
          "fr-ca": "Le Québec"
        },
        "SK": {
          "en-ca": "Saskatchewan",
          "fr-ca": "La Saskatchewan"
        },
        "YT": {
          "en-ca": "Yukon",
          "fr-ca": "Le Yukon"
        }
      });
    }

    get filteredJobs() {
      let province = this.selectedProvince;
      let jobs = this.model.jobs;
      let selectedDateString = this.selectedDate.toISOString().substr(0, 10);

      if (province) {
        jobs = jobs.filter(function (job) {
          return job.province == province;
        });
      }

      return jobs.filter(function (job) {
        if (job.datePosted) {
          return job.datePosted.substr(0, 10) == selectedDateString;
        } else {
          return false;
        }
      });
    }

    toggleCategory() {
      if (this.category == "jobs") {
        this.category = "careers";
      } else if (this.category == "careers") {
        this.category = "jobs";
      }
    }

    changeCalendarCenter(date) {
      this.center = date.date;
    }

    changeSelectedDate(date) {
      this.selectedDate = date.date;
    }

    navigateDate(direction) {
      this.calendarBuffer = true;
      let date = this.selectedDate;

      if (direction == "next") {
        date.setDate(date.getDate() + 1);
      } else if (direction == "previous") {
        date.setDate(date.getDate() - 1);
      }

      this.selectedDate = date;
      Ember.run.debounce(this, function () {
        this.calendarBuffer = false;
      }, 150);
    }

    selectProvince(province) {
      if (province == this.selectedProvince) {
        this.selectedProvince = "";
      } else {
        this.selectedProvince = province;
      }
    }

    selectProvinceMobile(event) {
      this.selectedProvince = event.target.value;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "calendarBuffer", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "center", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Date();
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedDate", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Date();
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedProvince", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "category", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "jobs";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleCategory", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeCalendarCenter", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "changeCalendarCenter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSelectedDate", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "changeSelectedDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "navigateDate", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "navigateDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectProvince", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "selectProvince"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectProvinceMobile", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "selectProvinceMobile"), _class.prototype)), _class));
  _exports.default = CareersAndJobsIndexController;
});